.animation-wrapper-top {
  width: 15%;
  position: fixed;
  left: 0;
  top: 20%;
}

.animation-wrapper-bottom {
  width: 15%;
  position: fixed;
  left: 0;
  top: 55%;
}

@media (max-width: 768px) {
  .animation-box {
    display: none;
  }
}
