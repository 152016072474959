html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
  color: $clr1;
  font-family: "Lato", sans-serif;
}

a {
  text-decoration: none;
  color: $clr1;
}

img {
  width: 100%;
}

li {
  list-style: none;
}

#page-wrapper {
  background: linear-gradient(to bottom, #1f2422, #0f2027);
  background: linear-gradient(to bottom, #061c20, #0f2027);
  background: #051115;

  background-attachment: fixed;
}

.main-container {
  width: 85%;
  margin-left: 15%;
}

.section {
  width: 60vw;
  margin: auto;
  border-bottom: 3px solid $clr4;
  padding-top: 50px;
  padding-bottom: 50px;

  h1 {
    text-align: center;
  }

  p {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
}

.line-dec {
  position: relative;
  width: 60px;
  height: 3px;
  background-color: $clr2;
  margin: 10px auto;
  margin-bottom: 30px;
}


.is-current a {
  transition: 0.3s;
  color: $clr2;
  background-color: $clr1;
}

@media (max-width: 768px) {
  .main-container {
    width: 100%;
    margin-left: unset;
  }

  .section {
    width: 80vw;
  }
}
