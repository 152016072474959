.section#contact {
  height: 100vh;
  p {
    text-align: center;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.input-box {
  margin-top: 10px;
  display: flex;
  gap: 10px;

  input,
  textarea {
    color: white;
    flex: 1;
    background-color: $clr4;
    border: 1.5px solid #8d9a99;
    padding: 5px;
  }

  input {
    height: 30px;
  }

  textarea {
    height: 175px;
  }

  button {
    border-radius: 15px;
    margin: 30px auto;
    display: inline-block;
    width: auto;
    padding: 10px 20px;
    background-color: $clr1;
    color: $clr2;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      color: $clr1;
      background-color: $clr2;
    }
  }
}

// contact box

.social-icons {
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;

  li {
    background-color: $clr4;
    transition: 0.5s;
    color: white;
    // margin-inline: 0.001%;
    margin-bottom: 3px;
    width: 40px;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      background-color: white;
      i {
        transition: 0.5s;
        color: $clr2;
      }
    }
  }

  a {
    text-align: center;
    width: 40px;
    line-height: 40px;
    display: block;
  }
}

// media queries

@media (max-width: 768px) {
  .input-box {
    flex-direction: column;
    textarea,
    input {
      flex: unset;
    }
  }
}
