.interests-item {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  i {
    margin-inline: 10px;
  }
}

.content-box {
  flex: 1;
  margin-top: 15px;
}

.img-container {
  flex: 1;
  img {
    border-radius: 15px;
  }
}

.odd {
  border-top: 3px solid $clr5;
  margin-top: 60px;
  padding-top: 60px;
  flex-direction: row-reverse;
}

// media queries

@media (max-width: 768px) {
  .interests-item {
    flex-direction: column;
  }
  .odd {
    padding-top: 30px;
  }
}
