.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: $clr1;

  p {
    color: $clr1;
    font-size: 17px;
    text-align: left;
    letter-spacing: 0.4px;
  }
}

.skill-item {
  background-color: $clr4;
  margin: 5px;
  padding: 20px;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-110%);
    background-color: rgba($color: #fff, $alpha: 0.8);
    transition: 0.45s ease-in-out;
    z-index: -1;
  }
  &:hover {
    // background-color: $clr1;
    // background-color: whitesmoke;
    color: black;
    &::after {
      transform: translateY(0%);
    }
    p,
    h2 {
      color: black;
    }
  }
}

.svg-container-light {
  width: 65px;
}

.svg-container-dark {
  width: 65px;
  display: none;
}

.skill-item.front-end {
  &:hover {
    .svg-container-dark {
      display: block;
    }
    .svg-container-light {
      display: none;
    }
  }
}
.skill-item.back-end {
  &:hover {
    .svg-container-dark {
      display: block;
    }
    .svg-container-light {
      display: none;
    }
  }
}
.skill-item.tools {
  &:hover {
    .svg-container-dark {
      display: block;
    }
    .svg-container-light {
      display: none;
    }
  }
}
.skill-item.soft-skills {
  &:hover {
    .svg-container-dark {
      display: block;
    }
    .svg-container-light {
      display: none;
    }
  }
}

.skill-title-wrap {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.skill-box {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  span {
    font-size: 17px;
    margin-bottom: 5px;
  }
}

// media
@media (max-width: 768px) {
  .skills-container {
    grid-template-columns: 80vw;
  }

  .skill-item {
    padding: 15px;
  }

  .skill-box {
    span {
      font-size: 16px;
    }
  }
}
