.work-item {
  padding-bottom: 30px;
  margin-bottom: 90px;
  border-bottom: 3px solid rgba(250, 250, 250, 0.25);
}

.work-item:last-child {
  border-bottom: unset;
  margin-bottom: unset;
  padding-bottom: unset;
}

.cta-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  font-size: 19px;
  margin-bottom: 30px;

  i {
    position: relative !important;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
  }

  .cta-btn-main {
    text-align: center;
    width: fit-content;
    border: 2px solid $clr2;
    background: $clr2;
    border-radius: 20px;
    padding-right: 30px;
    padding-left: 10px;
  }

  .cta-btn-sec {
    text-align: center;
    width: max-content;
    // line-height: 45px;
    background: transparent;
    color: $clr2;
    font-weight: bold;
    border: 2px solid $clr2;
    border-radius: 20px;
    padding-right: 30px;
    padding-left: 10px;
  }

  a {
    transition: 0.1s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.tag-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tag-item {
  font-size: 1rem;
  margin-inline: 3px;
  padding: 6px;
  background-color: $clr5;
  border-radius: 5px;
  color: $clr1;
}

.img-wrap {
  background-color: black;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
  line-height: 0;
  border-radius: 15px;
  border: 1px solid #3a4b47;

  img {
    transition: 0.3s ease-in-out;
    opacity: 0.8;
    &.first {
      opacity: 1;
    }
  }

  &:hover {
    img {
      opacity: 0.5;
      cursor: pointer;
      &.first {
        opacity: 0.7;
      }
    }
    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: "Font Awesome 6 Free";
      font-size: 40px;
      color: $clr3;
      content: "\f144";
    }
  }
}

// media queries

@media (max-width: 768px) {
  .cta-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    a {
      min-width: 50vw !important;
    }
  }
}
