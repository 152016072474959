.nav-wrapper {
  position: fixed;
  top: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.close-wrap {
  position: static;
}

.nav-container {
  background-color: $clr6;
  height: 100vh;
  width: 15%;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1.5px solid $clr5;
  transition: 0.3s;
  z-index: 10;

  .social-icons {
    gap: 5px;
    margin-top: 5px;
  }

  .social-item {
    transform: scale(0.8);
  }

  img {
    border-radius: 50%;
    width: 100px;
    margin: auto;
    user-select: none;
  }

  p {
    margin-top: 5px;
  }

  ul {
    padding: 0;
    margin-top: 5px;
  }

  .nav-item {
    list-style: none;
    border-bottom: 1.5px solid $clr5;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: bold;

    &:hover {
      a {
        transition: 0.3s;
        background-color: $clr1;
        color: $clr2;
      }
    }

    &:first-child {
      border-top: 1.5px solid $clr5;
    }

    a {
      height: 100%;
      display: block;
    }
  }
}

.nav-toggle-btn {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 100;
}

.nav-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.close-nav {
  transform: translate(-100%);
}

.toggle-nav-btn {
  // display: none;
  position: fixed;
  top: 10px;
  left: 5px;
  z-index: 20;
  font-size: 25px;
  line-height: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: $clr1;
  &:active {
    color: $clr2;
  }
}

.menu-btn-svg {
  transition: 0.3s ease-in-out;
  path {
    stroke: white !important;
  }
  &:active {
    path {
      stroke: $clr2 !important;
    }
  }
}

// media queries

@media (max-width: 768px) {
  .nav-container {
    width: 70%;
  }
}
